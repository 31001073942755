<template>
  <div>
    <v-text-field
      dense
      outlined
      autofocus
      type="password"
      class="mb-4"
      counter
      maxlength="50"
      :label="$t('site_admin.data.password')"
      :rules="[
        value => $helper.validator.requiredRule(value),
        value => $helper.validator.minTextRule(value, 4),
      ]"
      v-model="data.password"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      type="password"
      counter
      maxlength="50"
      :label="$t('site_admin.data.password_confirm')"
      :rules="[
        value => $helper.validator.requiredRule(value),
        value => $helper.validator.minTextRule(value, 4),
        value => $eagleLodash.isEqual(data.password, data.password_confirm) || $t('validate.error.password_not_match'),
      ]"
      v-model="data.password_confirm"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    data: {
      password: null,
      password_confirm: null,
    },
  }),
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.data))
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
